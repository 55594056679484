<template>
  <div class="DialogDescription">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.DialogDescription {
  color: var(--gray-3);
  line-height: 24px;
}
</style>
